<script setup lang="ts">
import { container } from "tsyringe";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

// adapter
import NavigationIndexWithRouteHandler from "@/adapter/navigation/workers/NavigationIndexWithRouteHandler";

// ui
import { NavigationLists, Logo } from "@/ui/shared/components";
import {
    NavigationMainMenuLists,
    NavigationSubMenuLists,
} from "@/ui/shared/repositories/NavigationMenuLists";

const route = useRoute();
const navigationIndexWithRouteHandler = container.resolve(NavigationIndexWithRouteHandler);

const currentSelectedMenuIndex = ref<number>(0);

/** 선택된 menu 로 index 수정 */
const displaySelectedMenu = (selectedIndex: number) => {
    currentSelectedMenuIndex.value = selectedIndex;
};

onMounted(() => {
    const currentIndex = navigationIndexWithRouteHandler.set(route.name as string);
    currentSelectedMenuIndex.value = currentIndex;
});
</script>

<template>
    <section class="bg-white h-lvh w-[18rem] p-[1.2rem]">
        <div class="flex flex-col items-center justify-between h-full">
            <div class="w-full h-auto">
                <div class="w-full mb-[4.8rem]">
                    <router-link to="/home">
                        <Logo />
                    </router-link>
                </div>
                <div class="w-full">
                    <NavigationLists
                        :lists="NavigationMainMenuLists"
                        :selected-index="currentSelectedMenuIndex"
                        @emit-selected-index="displaySelectedMenu"
                    />
                </div>
            </div>
            <div class="w-full">
                <NavigationLists
                    :lists="NavigationSubMenuLists"
                    :selected-index="currentSelectedMenuIndex"
                    @emit-selected-index="displaySelectedMenu"
                />
            </div>
        </div>
    </section>
</template>

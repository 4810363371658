<script setup lang="ts">
import { defineAsyncComponent, computed } from "vue";
import { container } from "tsyringe";

// adapter
import { ClientStateManager } from "@/adapter/common/stores/client/interface";

// instance
const modalSecondLayerManager =
    container.resolve<ClientStateManager.ModalSecondLayerManager>("ModalSecondLayerManager");

const modalState = modalSecondLayerManager.getState();

const component = computed(() => {
    const modalLayoutPath = modalState.modalLayout;
    return defineAsyncComponent(() => import(`./container/${modalLayoutPath}.vue`));
});

const closeModal = () => {
    modalSecondLayerManager.dispatch("unmountModal");
};
</script>

<template>
    <Teleport to="body" v-if="modalState.modalOpen">
        <div
            class="fixed top-0 left-0 flex items-center justify-center w-full h-full shadow-md bg-primary-black bg-opacity-30 z-1000"
            @click.self="closeModal"
        >
            <div class="flex items-center justify-center h-auto w-fit z-1000" tabindex="-1">
                <Transition name="fade">
                    <component :is="component" />
                </Transition>
            </div>
        </div>
    </Teleport>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition: all 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
    transform: translateY(-20px);

    opacity: 0;
}
</style>

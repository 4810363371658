import { singleton } from "tsyringe";

@singleton()
export default class NavigationIndexWithRouteHandler {
    public set = (pathName: string) => {
        const navigationIndexPerPathName = {
            home: 0,
            "student-setting": 1,
            "student-management": 2,
            "class-management": 3,
            "teacher-management": 4,
            "message-management": 5,
            "statistical-management": 6,
            "business-management": 7,
            setting: 9,
            "customer-center": 10,
        };

        return navigationIndexPerPathName[pathName];
    };
}

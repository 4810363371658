<script setup lang="ts">
import { computed } from "vue";
import { CommonAlert, CommonModal, CommonModalSecondLayer } from "@/ui/shared/components";
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";

const envDevelopment = computed(() => {
    return import.meta.env.DEV;
});
</script>

<template>
    <section>
        <component :is="$route.meta.layout">
            <router-view></router-view>
        </component>
        <CommonAlert />
        <CommonModal />
        <CommonModalSecondLayer />
        <VueQueryDevtools v-if="envDevelopment" />
    </section>
</template>
